.App {
    text-align: center;
}

.gradient {
    background: #2980b9; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to left bottom,
        #ffffff,
        #6dd5fa,
        #2980b9
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to left bottom,
        #ffffff,
        #6dd5fa,
        #2980b9
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
